import React from "react"
import { Link } from "react-scroll"
import AnimateHeight from 'react-animate-height'
import classNames from "classnames"
import ReactHtmlParser from "react-html-parser"

import AngleCR from "../../assets/svg/angle-c-right.svg"

import { attribufyString } from "../../helpers/common"

import { SectionText } from "../../elements/section-text"

import { TextPageMain } from "../../styles/legal/text-page.styles"
import { SectionTextMain, Container } from "../../styles/common.styles"

function calculateHeight() {
  const panelH = 74;
  return typeof window !== 'undefined' ? (window.innerWidth < 767 ? panelH : 'auto') : 'auto';
}

export const TextPageSection = ({ title, introContent, sections }) => {
  const [pannelStatus, setPanelStatus] = React.useState(calculateHeight());

  function togglePannel() {
    setPanelStatus(pannelStatus === calculateHeight() ? 'auto' : calculateHeight());
  }

  React.useEffect(() => {
    function handleResize() {
      setPanelStatus(pannelStatus === calculateHeight() ? calculateHeight() : 'auto');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextPageMain>
      <Container>
        <div className="row">
          <div className="pad-col legal-title-wrap">
            <h1 id="terms" className="legal-title">{title}</h1>
          </div>
        </div>
        <div className="row">
        {sections.length > 0 &&
            <aside className="pad-col legal-sidebar">
                <AnimateHeight
                id='sidebar-panel'
                className="anim-sidebar"
                duration={500}
                height={pannelStatus} >
                <ul>
                    {sections.map(({ title }, index) => (
                    <li key={index}>
                        <Link activeClass="active" href={`#${attribufyString(title)}`} to={attribufyString(title)} spy={true} smooth={true} offset={-160} duration={1000}>
                        <span>{title}</span>
                        </Link>
                    </li>
                    ))}
                </ul>
                </AnimateHeight>
                <button
                aria-expanded={pannelStatus !== calculateHeight}
                aria-controls='sidebar-panel'
                onClick={togglePannel}
                className={classNames('angle-btn', pannelStatus === 'auto' ? 'open' : 'closed')}
                >
                <AngleCR />
                </button>
            </aside>
        }
          <article className="pad-col legal-content-wrap">
            <div className="markdown">
              {introContent.map(({ title, text, contentType, wysiwyg }, index) => (
                <div key={index} id={attribufyString(title || `intro-content-${index}`)}>
                  {title && (
                    <h2 className={index === 0 ? 'intro-title' : 'section-title'}>
                      {title}
                    </h2>
                  )}
                  {text && (contentType ? <SectionTextMain className={index === 0 ? 'large-text' : ''} >{ReactHtmlParser(wysiwyg)}</SectionTextMain> : <SectionText text={text} variation={index === 0 ? 'large-text' : ''} />)}
                </div>
              ))}

              {sections.map(({ title, text, contentType, wysiwyg }, index) => (
                <div key={index} id={attribufyString(title || `section-${index}`)}>
                  {title !== null &&
                    <h2 className="section-title">
                      {`${index + 1}. ${title}`}
                    </h2>
                  }
                  {contentType ? <SectionTextMain>{ReactHtmlParser(wysiwyg)}</SectionTextMain> : <SectionText text={text} />}
                </div>
              ))}
            </div>
          </article>
        </div>
      </Container>
    </TextPageMain >
  )
}

