import React from "react"
import { graphql } from "gatsby"
import { animateScroll } from "react-scroll"

import AngleCR from "../assets/svg/angle-c-right.svg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TextPageSection } from "../components/legal/text-page-section"

const TextPageTemplate = ({
  data: {
    wpgraphql: {
      page: {
        title,
        textpageacf,
      }
    },
  }
}) => {

  return (
    <Layout headerColor={'white'} removeMainClass="true">
      <SEO title={title} />

      <TextPageSection
        title={title}
        introContent={textpageacf.introContent}
        sections={textpageacf.sections} />

      <button
        className="s-to-top-btn"
        onClick={() => {
          animateScroll.scrollTo(0);
        }}>
        <AngleCR />
      </button>

    </Layout>
  )
}

export default TextPageTemplate

export const homeTemplateQuery = graphql`
  query TextPageQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        textpageacf {
          introContent {
            contentType
            wysiwyg
            title
            text
          }
          sections {
            contentType
            wysiwyg
            text
            title
          }
        }
      }
    }
  }
`
