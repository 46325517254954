import styled from "styled-components"

import { SectionTextMain, col } from "../common.styles"

export const TextPageMain = styled.div`
padding: 330px 0 200px;

${props => props.theme.max('mac', () => (`
  padding: 230px 0 130px;
`))}

${props => props.theme.max('md', () => (`
  padding: 140px 0 80px;
`))}

.intro-title {
  font-family: ${props => props.theme.fonts.primary};
  padding-top: .35em;
  margin-bottom: 1em;
  font-size: 22px;
  text-transform: uppercase;

  ${props => props.theme.max('sm', () => (`
    line-height: ${32 / 22};
  `))}
}

.section-title {
  font-family: ${props => props.theme.fonts.primary};
  margin-bottom: 2.2em;
  font-size: 16px;
}

.row {
  position: relative;
}

.legal-sidebar {
  ${col(30)}
  
  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    position: relative;
    margin-bottom: 60px;
  `))}

  ul {
    margin: 0;
    margin-top: -2px;
    padding: 0;
    list-style: none;
    transition: 400ms;
  }

  li { 
    padding: 0 2.1em 1.09em;

    ${props => props.theme.max('sm', () => (`
      padding: 10px 0;

      &:first-child {
        padding: 10px 0;
        min-height: 74px;
        display: flex;
        align-items: center;
      }
    `))}

    &:last-child {
      padding-bottom: 0;

      ${props => props.theme.max('sm', () => (`
        padding-bottom: 10px;
      `))}
    }
  }

  a {
    position: relative;
    display: block;
    line-height: 1.63;
    font-size: 12px;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.primary100};
    font-weight: bold;
    text-decoration: none;
    transition: 400ms;
    padding: 0.75em 0 .25em;

    &:before {
      content: '';
      position: absolute;
      left: -40px;
      top: 40%;
      pointer-events: none;
      width: 40px;
      border-top: 1px solid currentColor;
      transform-origin: left center;
      transform: scale(0, 1);
      transition: 400ms;
    }

    span {
      display: inline-block;
      transition: 400ms;
    }

    &:not(.active) {
      ${props => props.theme.min('sm', () => (`
        opacity: .5;
      `))}
    }

    &.active {
      &:before {
        transform: scale(1, 1);
      }

      span {
        transform: translate(40px, 0);
      }
    }

    ${props => props.theme.min('sm', () => (`
      &:hover {
        opacity: 1;

        span {
          transform: translate(40px, 0);
        }
      }
    `))}
  }
}

.legal-title-wrap,
.legal-content-wrap {
  ${col(70)}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

.legal-content-wrap {
  ol,
  ul {
    margin-top: 1.9em;
    margin-bottom: 1.9em;
  }

  ol {
    list-style-type: upper-latin;
  }
  strong{ font-weight: bold; }
}

.legal-title-wrap {
  margin-left: auto;
  
  ${props => props.theme.max('sm', () => (`
    padding-top: 70px;
  `))}
}

${SectionTextMain} {
  &.large-text {
    margin-bottom: 5.3em;
    font-family: ${props => props.theme.fonts.primary};

    ${props => props.theme.max('sm', () => (`
      line-height: 1.68;
      margin-bottom: 3.1em;
    `))}
  }
}

.anim-sidebar {
  position: sticky;
  top: 160px;
  left: 0;
  transition: 400ms;
  /* overflow-y: auto; */
  /* max-height: calc(95vh - 160px); */

  .has-hidden-header & {
    top: 30px;
    /* max-height: 95vh; */
  }

  ${props => props.theme.max('sm', () => (`
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-top: 1px solid rgba(0,0,0,.1);
  `))}
}

.angle-btn {
  background: none;
  appearance: none;
  border-radius: 0;
  border: none;
  position: absolute;
  right: 0;
  top: 14px;
  
  svg {
    transform: rotate(90deg);
  }

  &.open {
    svg {
      transform: rotate(-90deg);
    }
  }

  ${props => props.theme.min('sm', () => (`
    display: none;
  `))}

  .angle-r-bg {
    display: none;
  }

  .angle-r-in {
    stroke: ${props => props.theme.colors.primary};
  }
}
`
